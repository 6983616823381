.education {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.education__content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 0.5%;
  padding: 1%;
}

.education__accordion {
  min-width: 350px;
  height: auto;
}