.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5rem;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.header>* {
  align-self: center;
}

.header__title {
  font-weight: bolder;
  text-decoration: none;
  color: black;
}

.header__title:hover {
  color: blue;
}

.header__links {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-right: 50px;
}

.header__link {
  color: black;
  text-decoration: none;
}

.header__link:hover {
  color: blue;
}