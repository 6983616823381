.work__container {
  flex-grow: 1;
  padding: 40px 80px;
}

.work__title {
  text-align: center;
  margin-bottom: 5px;
}

.work__description {
  text-align: center;
  margin-bottom: 25px;
}

.work__item {
  background-color: #fff;
  /* Light mode background color */
  color: rgba(0, 0, 0, 0.87);
  /* Light mode text color */
  padding: 24px;
  /* Assuming theme.spacing(3) translates to 24px */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* Assuming theme.typography.body2 */
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.dark .Item {
  background-color: #1A2027;
  /* Dark mode background color */
}

.Img {
  margin: auto;
  display: block;
  max-width: 100%;
  height: 50px;
}