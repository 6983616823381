.projects {
  padding: 4%;
}

.projects h2 {
  text-align: center;
  margin-bottom: 4%;
}

.projects__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 20px;
}

/* Crypto */

.project-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.project-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.project-image img {
  width: 50%;
  height: auto;
}

.project-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
  gap: 10px;
  margin: 20px 0 10px 0;

}

.project-title {
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 30px;
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.project-title:hover {
  color: blue;
}

.back-link {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #3f51b5;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.project-description {
  padding: 0px 30px 0px 30px;
}